import React from 'react'

const hyperItalics = <span>Whelton PK, Carey RM, Aronow WS, et al. 2017 ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA guideline for the prevention, detection, evaluation, and management of high blood pressure in adults: executive summary: a report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines [published correction appears in <span style={{fontStyle: 'italic'}}>Hypertension</span>. 2018;71(6):e136-e139] [published correction appears in <span style={{fontStyle: 'italic'}}>Hypertension</span>. 2018;72(3):e33].</span>

export const references = [
  {
    content:
      'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study.',
    source: 'Sleep Med.',
    info: '2017;33:13-18.',
  },
  {
    content:
      'Ohayon MM. Narcolepsy is complicated by high medical and psychiatric comorbidities: a comparison with the general population.',
    source: 'Sleep Med.',
    info: '  2013;14(6):488-492.',
  },
  {
    content: hyperItalics,
    source: 'Hypertension. ',
    info: '2018;71(6):1269-1324.',
  },
  {
    content:
      'Jackson SL, King SM, Zhao L, Cogswell ME. Prevalence of excess sodium intake in the United States—NHANES, 2009-2012. ',
    source: 'MMWR Morb Mortal Wkly Rep. ',
    info: '2016;64(52):1393-1397.',
  },
  {
    content:
      'Benjamin EJ, Muntner P, Alonso A, et al; American Heart Association Council on Epidemiology and Prevention Statistics Committee and Stroke Statistics Subcommittee. Heart disease and stroke statistics—2019 update: a report from the American Heart Association.',
    source: 'Circulation.',
    info: '2019;139(10):e56-e528.',
  },
  {
    content:
      "Lloyd-Jones DM, Hong Y, Labarthe D, et al. Defining and setting national goals for cardiovascular health promotion and disease reduction: the American Heart Association's strategic Impact Goal through 2020 and beyond.",
    source: 'Circulation.',
    info: '2010;121(4):586-613.',
  },
  {
    content:
      'Salehi-Abargouei A, Maghsoudi Z, Shirani F, Azadbakht L. Effects of Dietary Approaches to Stop Hypertension (DASH)-style diet on fatal or nonfatal cardiovascular diseases—incidence: a systematic review and meta-analysis on observational prospective studies.',
    source: 'Nutrition.',
    info: '2013;29(4):611–618.',
  },
  {
    content: 'Quan SF. Sleep disturbances and their relationship to cardiovascular disease.',
    source: 'Am J Lifestyle Med.',
    info: '2009;3(1 suppl):55s-59s.',
  },
  {
    content: 'Centers for Disease Control and Prevention. Sleep and sleep disorders.',
    source: 'Sleep and Chronic Disease.',
    info: 'https://www.cdc.gov/sleep/about_sleep/chronic_disease.html. Page last reviewed: August 8, 2018.',
  },
  {
    content:
      'McAlpine CS, Kiss MG, Rattik S, et al. Sleep modulates haematopoiesis and protects against atherosclerosis.',
    source: 'Nature.',
    info: '2019;566(7744):383-387.',
  },
  {
    content:
      'Dauvilliers Y, Jaussent I, Krams B, et al. Non-dipping blood pressure profile in narcolepsy with cataplexy.',
    source: 'PLoS One.',
    info: '2012;7(6):e38977.',
  },
  {
    content:
      'Grimaldi D, Calandra-Buonaura G, Provini F, et al. Abnormal sleep-cardiovascular system interaction in narcolepsy with cataplexy: effects of hypocretin deficiency in humans.',
    source: 'Sleep.',
    info: '2012:35(4):519-528.',
  },
  {
    content: 'Thorpy MJ, Hiller G. The medical and economic burden of narcolepsy: implications for managed care.',
    source: 'Am Health Drug Benefits. ',
    info: '2017;10(5):233-241.',
  },
  {
    content:
      'Thorpy M, Morse AM. Reducing the clinical and socioeconomic burden of narcolepsy by earlier diagnosis and effective treatment. ',
    source: 'Sleep Med Clin. ',
    info: '2017;12(1):61-71.',
  },
  {
    content: ' American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },
  {
    content:
      'Herrington W, Lacey B, Sherliker P, Armitage J, Lewington S. Epidemiology of atherosclerosis and the potential to reduce the global burden of atherothrombotic disease.',
    source: ' Circ Res.',
    info: ' 2016;118(4):535-546.',
  },
  {
    content: 'Frostegård J. Immunity, atherosclerosis and cardiovascular disease.',
    source: ' BMC Med.',
    info: ' 2013;11:117.',
  },
  {
    content:
      'Hermida RC, Ayala DE, Mojón A, Fernández JR. Blunted sleep-time relative blood pressure decline increases cardiovascular risk independent of blood pressure level—the “normotensive non-dipper” paradox.',
    source: ' Chronobiol Int.',
    info: ' 2013;30(1-2):87-98.',
  },
  {
    content:
      'Ohkubo T, Hozawa A, Yamaguchi J, et al. Prognostic significance of the nocturnal decline in blood pressure in individuals with and without high 24-h blood pressure: the Ohasama study.',
    source: ' J Hypertens.',
    info: ' 2002;20(11):2183-2189.',
  },
  {
    content:
      'Ben-Dov IZ, Kark JD, Ben-Ishay D, Mekler J, Ben-Arie L, Bursztyn M. Predictors of all-cause mortality in clinical ambulatory monitoring: unique aspects of blood pressure during sleep.',
    source: ' Hypertension.',
    info: ' 2007;49(6):1235-1241.',
  },
  {
    content:
      'GBD 2013 Mortality and Causes of Death Collaborators. Global, regional, and national age–sex specific all-cause and cause-specific mortality for 240 causes of death, 1990–2013: a systematic analysis for the Global Burden of Disease Study 2013.',
    source: ' Lancet.',
    info: ' 2015;385(9963):117-171.',
  },
]